import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  resetStore,
  setSideBar,
} from "../../common/redux/action/SidbarIndexAction";
import { BsPersonAdd } from "react-icons/bs";
import { getCalculateData } from "../Calculate/repo/GetCalculateData";
import { setCalculate } from "../../common/redux/action/CalculateAction";
import { API_URL } from "../../Repository/URL";

import { useTranslation } from "react-i18next";
import { removeCookie } from "../../common/Cookies/user_cookies";
import { tokenName } from "../../util/const";
import CoupangAd, { CoupangSrc } from "../../util/iframe";

export const ReSidBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);
  const sidebarIndex = useSelector((state) => state.sidebar.sidebarIndex);
  const profileImg = useSelector((state) => state.userprofileImg?.profileImage);
  const url = API_URL;
  const userData = useSelector((state) => state.profileState?.profile);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleLink = () => {
    removeCookie(tokenName);
    dispatch(resetStore());
    navigate("/renewalLogin");
  };

  const handleLinkPage = async (index) => {
    const resp = await dispatch(setSideBar(index));
  };

  const handleLinkThreePage = async () => {
    const resp = await getCalculateData();
    if (resp.msg === "Success") {
      dispatch(setCalculate(resp.data.data));
      handleLinkPage(3);
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.status === 403) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.status === 500) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.data.message === "등록한정보가 없음") {
        dispatch(setCalculate("nodata"));
        handleLinkPage(3);
        return;
      }
      handleLinkPage(0);
      navigate("/ReMyPage");
      console.log("실패", resp);
      return;
    }
  };
  const handleLogo = () => {
    handleLinkPage(0);
    navigate("/ReMyPage");
  };

  const getLinkStyle = (index) => {
    return {
      backgroundColor: sidebarIndex === index ? "#357fed" : "#ffffff",
      color: sidebarIndex === index ? "357fed" : "#fffff",
      borderRadius: "16px",
    };
  };

  const getSpanText = (index) => {
    return {
      color: sidebarIndex === index ? "#ffffff" : "black",
      whiteSpace: "pre-line",
    };
  };

  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 기본 화면 높이와 marginTop 설정
  // 기본 화면 높이와 marginTop 설정
  const baseHeight = 800; // 기준이 되는 화면 높이
  const baseMarginTop = 220; // 기본 marginTop

  // 동적 marginTop 계산 함수
  const getDynamicMarginTop = () => {
    const heightDifference = baseHeight - windowHeight;

    // 화면 높이가 줄어든 경우에만 marginTop 줄이기
    if (heightDifference > 0) {
      // 10px씩 줄어들 때마다 marginTop을 10px 줄임
      const reducedMargin = Math.floor(heightDifference / 10) * 10;
      return Math.max(baseMarginTop - reducedMargin, 0); // 음수 방지
    }

    return baseMarginTop; // 화면 높이가 기준보다 클 때는 기본값 유지
  };
  return (
    <div id="sidebar" data-inc="_inc-sidebar.html">
      <div
        className="sidebar-header"
        style={{
          cursor: `pointer`,
        }}
        onClick={() => handleLogo()}
      >
        <h1 className="sidebar-title">
          <img
            src="/group.svg"
            alt="icon"
            style={{ width: `50px`, height: "50px", marginRight: "1rem" }}
          />
          <img
            src={languageData === "en" ? "/GroupEn.svg" : "/LogoBlack.svg"}
            alt=""
            style={{ width: `120px`, height: "55px" }}
          />
        </h1>
      </div>
      <div className="sidebar-body">
        <nav id="gnb-area" className="variable-area">
          <ul className="gnb-list">
            <li className="" style={getLinkStyle(0)}>
              <Link
                to="/ReMyPage"
                data-index="0"
                onClick={() => handleLinkPage(0)}
              >
                <i className="ico ico-dashboard-gray-md"></i>
                <span className="text" style={getSpanText(0)}>
                  {t("sponsorShipStatusText")}
                </span>
              </Link>
            </li>
            <li className="" style={getLinkStyle(1)}>
              <Link
                to="/ReModifyPage"
                data-index="1"
                onClick={() => handleLinkPage(1)}
              >
                <i className="ico ico-dashboard-gray-md"></i>
                <span className="text" style={getSpanText(1)}>
                  {t("profileSettingText")}
                </span>
              </Link>
            </li>
            <li className="" style={getLinkStyle(2)}>
              <Link
                to="/reCreateButton"
                data-index="2"
                onClick={() => handleLinkPage(2)}
              >
                <i className="ico ico-dashboard-gray-md"></i>
                <span className="text" style={getSpanText(2)}>
                  {t("buttonCreateText")}
                </span>
              </Link>
            </li>
            <li className="" style={getLinkStyle(3)}>
              <Link
                to="/CalculatePage"
                data-index="3"
                onClick={() => handleLinkThreePage()}
              >
                <i className="ico ico-dashboard-gray-md"></i>
                <span className="text" style={getSpanText(3)}>
                  {t("settlementRegistrationText")}
                </span>
              </Link>
            </li>
            <li className="" style={getLinkStyle(5)}>
              <Link
                to="/Confirmation"
                data-index="3"
                onClick={() => handleLinkPage(5)}
              >
                <i className="ico ico-dashboard-gray-md"></i>
                <span className="text" style={getSpanText(5)}>
                  {t("confirmationText")}
                </span>
              </Link>
            </li>
            <li className="">
              {languageData === "en" ? (
                <Link
                  to="https://splendid-tuberose-a37.notion.site/HANZAN-Guideline-989ae6831bf7416688d4375c78e7bf1d"
                  data-index="4"
                >
                  <i className="ico ico-dashboard-gray-md"></i>
                  <span className="text">{t("guideText")}</span>
                </Link>
              ) : (
                <Link to="https://onemorelatte.oopy.io/" data-index="4">
                  <i className="ico ico-dashboard-gray-md"></i>
                  <span className="text">{t("guideText")}</span>
                </Link>
              )}
            </li>

            <li
              style={{
                marginLeft: `-30px`,
                height: `100%`,
                width: `100%`,
                maxHeight: `400px`,
                maxWidth: `180px`,
                marginTop: `${getDynamicMarginTop()}px`, // 동적으로 marginTop 설정
                // position: "absolute", // 고정 배치
                bottom: 0, // 부모 요소의 맨 아래로 배치
              }}
            >
              <CoupangAd src={CoupangSrc} width="220" height="200" />
            </li>
          </ul>
        </nav>
      </div>
      <div className="user-info-area">
        <div className="info">
          <span className="profile">
            <button type="button" className="profile-thumb">
              {userData?.profileImgPath && !imgError ? (
                <img
                  src={userData?.profileImgPath}
                  alt="프로필 이미지"
                  onError={handleImgError}
                />
              ) : (
                <BsPersonAdd />
              )}
            </button>

            <strong className="text-normal">
              {userData?.name !== null || userData?.name !== undefined ? (
                userData?.name
              ) : (
                <div></div>
              )}
            </strong>
          </span>
          <span className="time-info">
            <button className="btn btn-sm btn-gray" onClick={handleLink}>
              <i className="ico ico-edit-gray"></i>
              {t("logOutText")}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};
