import numeral from "numeral";

export const koreanRegex = /^[가-힣]+$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const regUniqueRegex =
  /^\d{2}([0]\d|1[0-2])([0][1-9]|[12]\d|3[0-1])[1-4]\d{6}$/;

export const dontaionMobileSize = 800;
export const phonNumberRegex = /^010([0-9]{3,4})([0-9]{4})$/;

export const API_KEY = "COFFEE1234!!!";
export const KaKaoLoginURL = "https://acoffee.shop/auth/kakao/login";
export const numericRegex = /^\d+$/;

export const isValidEmail = (email) => {
  return emailRegex.test(email);
};

export const formatNumber = (num) => {
  return numeral(num).format("0,0");
};

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith("+82 ")) {
    return phoneNumber.replace("+82 ", "0");
  }
  return phoneNumber;
};
export const formatKoreanPhoneNumber = (phoneNumber) => {
  // 숫자만 남기기
  const cleanNumber = phoneNumber.replace(/[^0-9]/g, "");

  // 전화번호가 11자리인 경우 (앞에 0을 제거한 후 10자리로 변환)
  if (cleanNumber.length === 11 && cleanNumber.startsWith("010")) {
    return cleanNumber.replace(/^010(\d{4})(\d{4})$/, "10-$1-$2");
  }

  // 전화번호가 10자리인 경우 (지역번호 2자리)
  if (cleanNumber.length === 10) {
    return cleanNumber.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
  }

  // 그 외: 변환하지 않음
  return phoneNumber;
};

export const ButtonUrlText = {
  ButtonY400: "https://xrai-api.com/coffeeimg/y400.png",
  ButtonB400: "https://xrai-api.com/coffeeimg/b400.png",
  ButtonYl400: "https://xrai-api.com/coffeeimg/yl400.png",
  ButtonW400: "https://xrai-api.com/coffeeimg/w400.png",
  ButtonR400: "https://xrai-api.com/coffeeimg/r400.png",

  ButtonY200: "https://xrai-api.com/coffeeimg/y200.png",
  ButtonB200: "https://xrai-api.com/coffeeimg/b200.png",
  ButtonYl200: "https://xrai-api.com/coffeeimg/yl200.png",
  ButtonW200: "https://xrai-api.com/coffeeimg/w200.png",
  ButtonR200: "https://xrai-api.com/coffeeimg/r200.png",
};

export const cookieAge = 30 * 60;
export const tokenName = "accessToken";

export const dateLocaleText = "ko";

export const validateName = (name) => {
  if (!koreanRegex.test(name)) {
    return false;
  }
  return true;
};

export const validateCode = (code) => {
  if (!regUniqueRegex.test(code)) {
    return false;
  }
  return true;
};

export const validateNumber = (number) => {
  if (!numericRegex.test(number)) {
    return false;
  }
  return true;
};

export const validatePhonNumber = (phone) => {
  if (!phonNumberRegex.test(phone)) {
    return false;
  }
  return true;
};
