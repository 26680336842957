import styled from "styled-components";

export const IframeOutBox = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 40px;
`;

export const IframeInBox = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  max-height: 150px;
  max-width: 640px;
  border-radius: 16px;
  margin-right: 50px;
  margin-left: 50px;
`;
