import React from "react";

import { useTranslation } from "react-i18next";
import { CustomSVG } from "../../../home/DonationPage/css/Tolk";

const KaKaoIconButton = () => {
  const { t, i18n } = useTranslation();
  const goKakao = () => {
    const url = " http://pf.kakao.com/_QeXXG/chat";

    window.open(url, "팝업 이름", "width=530,height=718,left=00,top=100");
  };

  return (
    <div className="" onClick={goKakao} style={{ cursor: `pointer` }}>
      <dl
        style={{
          backgroundColor: "#FFD400",
          borderRadius: `8px`,
          padding: `5px`,
          alignItems: `center`,
          display: `flex`,
          justifyContent: `center`,
          height: `3.5rem`,
        }}
      >
        <CustomSVG />
      </dl>
    </div>
  );
};

export default KaKaoIconButton;
