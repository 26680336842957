import React from "react";

import { useTranslation } from "react-i18next";
import { CustomSVG } from "../../../home/DonationPage/css/Tolk";

const ReKaKaoButton = () => {
  const { t, i18n } = useTranslation();
  const goKakao = () => {
    const url = " http://pf.kakao.com/_QeXXG/chat";

    window.open(url, "팝업 이름", "width=530,height=718,left=00,top=100");
  };

  return (
    <div
      className="item-body"
      onClick={goKakao}
      style={{ width: `100%`, cursor: `pointer` }}
    >
      <dl
        style={{
          backgroundColor: "#FFD400",
          justifyContent: `center`,
          alignItems: `center`,
          display: `flex`,
          height: `100%`,
          borderRadius: `8px`,
        }}
      >
        <CustomSVG />
        <strong
          style={{
            fontSize: "15px",
            marginLeft: `5px`,
          }}
        >
          {t("contactUs")}
        </strong>
      </dl>
    </div>
  );
};

export default ReKaKaoButton;
