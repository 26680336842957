import styled from "styled-components";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";

export const CreateButtonUpStyled = styled.button`
  width: 10%;
  appearance: none;
  text-align: center !important;
  color: #fff;
  background: #357fed;
  vertical-align: middle;
  font-weight: 800;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
`;

export const CreateButtonUpStyledM = styled.button`
  width: 40%;
  appearance: none;
  text-align: center !important;
  color: #fff;
  background: #357fed;
  vertical-align: middle;
  font-weight: 800;
  font-size: 14px;
  height: 40px;
  border-radius: 9px;
  line-height: 30px;
  transition-duration: 0.2s;
  transition-property: color, border-color, box-shadow;
  will-change: color, border-color, box-shadow;
`;

export const CreateButtonCardStyled = styled.div`
  margin-left: 2%;
  margin-right: 2%;
`;

export const CreateImgDivStyled = styled.div`
  margin-bottom: 2rem;
`;

export const CreateImgStyled = styled.img`
  margin-right: 1rem;
`;

export const CreateTitleDivStyled = styled.div`
  display: flex;
`;

export const CreateContTitleDivStyled = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const CreateFaRegIcon = styled(FaRegCircleCheck)`
  height: 17px;
  width: 17px;
  margin-right: 0.5rem;
`;

export const CreateDivStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const CreateButtonStyled = styled.button`
  margin-right: 10px;
`;

export const CreateMarginDivStyled = styled.div`
  margin: 2% 2% 2% 2%;
`;

export const CreateContentTitleStyled = styled.h3`
  margin-right: 1rem;
`;

export const CreateCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateCardLinkCopyBoxStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const CreateFaLinkIconStyled = styled(FaLink)`
  margin-right: 20px;
  width: 20px;
  height: 20px;
`;

export const CreateLinkTextStyled = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
