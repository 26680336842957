import styled from "styled-components";

export const RegistrationButton = styled.button`
  font-weight: 800;
  height: 2.5rem;
  margin-left: 15px;
  width: 7rem;
`;

export const CalculateButton = styled.button`
  height: 3rem;
  width: 6rem;
  margin-top: 1rem;
  font-size: 18px;
  margin-right: 1.5rem;
`;

export const RequiredInputBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const QuickLinkButtonSize = styled.button`
  margin-top: 3%;
  margin-left: 2%;
  width: 100%;
`;

export const BusinessButton = styled.button`
  margin-top: 3%;
  margin-right: 2%;
  width: 100%;
`;

export const MQuickLinkButtonSize = styled.button`
  /* margin-top: 3%; */
  margin-left: -5%;
  width: 100%;
`;
export const MQuickLinkButtonSize2 = styled.button`
  /* margin-left: %; */
  width: 100%;
`;

export const MQuickLinkButtonSize3 = styled.button`
  /* margin-top: 3%; */
  margin-right: 0%;
  width: 100%;
`;
