import ReFooter from "../../../../common/Footer/screen/ReFooter";
import { ReSidBar } from "../../../ReSidBar/ReSidBar";
import TextEditor from "../../components/draft";
import React, { useEffect, useState } from "react";
import { ProfileImageUpload } from "../components/ProfileDrop";
import {
  ModifyTableDivStyled,
  ModifyTableImgDivStyled,
  ModifyTableKaKaoDivStyled,
} from "../css/ReModifyStyled";
import { LoginKaKaoWingStyled } from "../../../login/Renewal/css/ReLoginPageStyled";
import wing from "../../../login/Renewal/img/pngwing.com.png";
import { PasswordChangeModal } from "../components/PasswordChange";
import { NickNameChangeModal } from "../components/NickNameChange";
import { BankAccountChangeModal } from "../components/BankAccountChange";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber } from "../../../../util/const";
import { postChangeBankAndAccount } from "../repo/ChangeBank";
import Swal from "sweetalert2";
import { postChangeNickName } from "../repo/ChangeNickName";
import { useNavigate } from "react-router";
import {
  setAccount,
  setBank,
  setNickNameData,
} from "../../../../common/redux/action/ProfileAction";
import { useTranslation } from "react-i18next";
import QuillEditor from "../../components/quil";
import { handleNickNameChange } from "../components/ModifyHandle";
import {
  MobileBackgroundDiv,
  MobileBackgroundDiv2,
  MobileSizeBoxTop20,
  MobileSizeBoxTop30,
} from "../../../MyPage/Renewal/css/ReMyPageMStyld";
import { MSidBar } from "../../../MSidBar/screen/MobileSidBar";
import { setSideBar } from "../../../../common/redux/action/SidbarIndexAction";
import { getCalculateData } from "../../../Calculate/repo/GetCalculateData";
import { setCalculate } from "../../../../common/redux/action/CalculateAction";
import CoupangAd, { CoupangSrc3 } from "../../../../util/iframe";
export const ReModifyPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useData = useSelector((state) => state.profileState.profile);

  const userProfileData = useSelector((state) => state?.profileState);
  const typeLogin = useSelector((state) => state?.loginTypeState);
  const languageData = useSelector((state) => state.languageType?.type);

  const { t } = useTranslation();
  const [editorData, setEditorData] = useState();
  const [selectedBank, setSelectedBank] = useState(
    userProfileData?.profile?.bank || ""
  );
  const [isBankAccount, setBankAccount] = useState(
    userProfileData?.profile?.account || ""
  );
  const [isPhonNumber, setPhonNumber] = useState("");
  const [nickName, setNickName] = useState("");
  const [isPassword, setIsPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [loginType, setLoginType] = useState(false);

  const handleBankUpdate = async (bankName, accountNumber) => {
    try {
      const resp = await postChangeBankAndAccount(bankName, accountNumber);

      if (resp === "Fail") {
        Swal.fire({
          title: `${t("accountNumberChangeFailText")}`,
          icon: "error",
        });
        return;
      } else {
        Swal.fire({
          title: `${t("accountNumberChangeSuccessText")}`,
          icon: "success",
        });
        dispatch(setBank(bankName));
        dispatch(setAccount(accountNumber));
        setSelectedBank(bankName);
        setBankAccount(accountNumber);
      }
    } catch (error) {
      return;
    }
  };

  const handleNickNameChangee = async (newNickName) => {
    await handleNickNameChange(
      newNickName,
      dispatch,
      setNickName,
      t,
      typeLogin
    );
    // if (
    //   !newNickName.trim() ||
    //   newNickName.includes(" ") ||
    //   newNickName.trim().length < 2
    // ) {
    //   Swal.fire({
    //     title: `${t("notTrimTwoLengthInputText")}`,
    //     icon: "error",
    //   });
    //   return;
    // }

    // try {
    //   const resp = await postChangeNickName(newNickName);

    //   ///공통으로 안옴
    //   if (resp.msg === "Fail") {
    //     if (resp.data.message === "바꾼지 한달 안됨") {
    //       Swal.fire({
    //         title: `${t("changeNickNameDayText")}`,
    //         icon: "error",
    //       });
    //     } else {
    //       Swal.fire({
    //         title: `${t("nicknameChangeFailedText")}`,
    //         icon: "error",
    //       });
    //     }
    //     return;
    //   } else {
    //     Swal.fire({
    //       title: `${t("nicknameChangeSuccessText")}`,
    //       icon: "success",
    //     });
    //     dispatch(setNickNameData(newNickName));
    //     setNickName(newNickName);
    //   }
    // } catch (error) {}

    // setNickName(newNickName);
  };

  const handlePasswordChange = async (newPassword) => {
    setIsPassword(newPassword);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (typeLogin === "Kakao") {
          setLoginType(true);
        }
        setNickName(userProfileData?.profile?.nickname || "");
        const phon = userProfileData?.profile?.phone || "";
        const formattedNumber = formatPhoneNumber(phon);
        setPhonNumber(formattedNumber);

        setEditorData(userProfileData?.profile?.text);
        if (
          userProfileData?.bank === null ||
          userProfileData?.bank === undefined
        ) {
        } else {
          setSelectedBank(userProfileData?.profile?.bank || "");
        }

        if (
          userProfileData?.account === null ||
          userProfileData?.bank === undefined
        ) {
        } else {
          setBankAccount(userProfileData?.profile?.account || "");
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  /// 작은화면
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLinkPage = async (index) => {
    dispatch(setSideBar(index));
  };

  const handleLinkThreePage = async () => {
    const resp = await getCalculateData();
    if (resp.msg === "Success") {
      dispatch(setCalculate(resp.data.data));
      handleLinkPage(3);
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.status === 403) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.status === 500) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.data.message === "등록한정보가 없음") {
        dispatch(setCalculate("nodata"));
        handleLinkPage(3);
        return;
      }
      handleLinkPage(0);
      navigate("/ReMyPage");
      console.log("실패", resp);
      return;
    }
  };

  return (
    <>
      {windowWidth >= 1100 ? (
        <div className="layout-type-main">
          <div className="wrapper nav-mode-1">
            <div id="container">
              <ReSidBar />
              <div className="content-body">
                <div id="content">
                  <br />
                  <br />
                  <div
                    className="card"
                    style={{
                      marginLeft: `2%`,
                      marginRight: `2%`,
                    }}
                  >
                    <div className="card">
                      <div className="table table-row">
                        <div className="table-inner">
                          <div style={{ marginBottom: `3rem` }}>
                            <img
                              src="/group.svg"
                              alt=""
                              style={{ marginRight: `1rem` }}
                            />
                            {languageData === "en" ? (
                              <img src="/GroupEn.svg" alt="" width={300} />
                            ) : (
                              <img src="/LogoBlack.svg" alt="" />
                            )}
                          </div>
                          <div style={{ display: `flex` }}>
                            <div className="section-myinfo">
                              <div className="info-item myinfo">
                                <ProfileImageUpload
                                  profileImg={
                                    userProfileData?.profile?.profileImgPath
                                  }
                                />
                                <dl className="info">
                                  <dt className="name">
                                    {userProfileData?.profile?.name}
                                  </dt>
                                </dl>
                              </div>
                            </div>
                            <table style={{ marginLeft: `20px` }}>
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    <span
                                      style={{
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {t("nameTitleText")}
                                    </span>
                                  </th>
                                  <td>
                                    <input
                                      style={{ textAlign: "left" }}
                                      className="form-control"
                                      disabled={true}
                                      value={userProfileData?.profile?.name}
                                    ></input>
                                  </td>
                                  <th scope="row">{t("titleNickName")}</th>
                                  <td>
                                    <ModifyTableDivStyled>
                                      <input
                                        className="form-control"
                                        placeholder={t("inputNickNameText")}
                                        value={nickName}
                                        disabled={true}
                                      ></input>
                                      <NickNameChangeModal
                                        onNickNameChange={handleNickNameChangee}
                                      />
                                    </ModifyTableDivStyled>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">{t("callNumberText")}</th>
                                  <td>
                                    <input
                                      className="form-control"
                                      disabled={true}
                                      value={isPhonNumber}
                                    ></input>
                                  </td>
                                  <th scope="row">E-Mail</th>
                                  <td>
                                    <div
                                      style={{ display: `flex`, width: `100%` }}
                                    >
                                      <input
                                        className="form-control"
                                        value={userProfileData?.profile?.email}
                                        disabled={true}
                                      ></input>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  {typeLogin.type === "Kakao" ? (
                                    <>
                                      <th scope="row">{t("loginTypeText")}</th>
                                      <td>
                                        <ModifyTableKaKaoDivStyled>
                                          <ModifyTableImgDivStyled className="  btn-block">
                                            <LoginKaKaoWingStyled
                                              src={wing}
                                              alt="말풍선"
                                            />
                                            KaKao
                                          </ModifyTableImgDivStyled>
                                        </ModifyTableKaKaoDivStyled>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <th scope="row">{t("passwordText")}</th>
                                      <td>
                                        <div
                                          style={{
                                            display: `flex`,
                                            width: `100%`,
                                            alignItems: `center`,
                                          }}
                                        >
                                          <input
                                            className="form-control"
                                            placeholder={t("passwordText")}
                                            type="password"
                                            value="password"
                                            // value={isPassword}
                                            disabled={true}
                                          ></input>
                                          <PasswordChangeModal
                                            onPassword={handlePasswordChange}
                                          />
                                        </div>
                                      </td>
                                    </>
                                  )}

                                  {/* {languageData === "en" ? (
                                    <th scope="row"></th>
                                  ) : (
                                    <th scope="row">
                                      {t("accountNumberText")}
                                    </th>
                                  )} */}
                                  {/* <th scope="row">{t("accountNumberText")}</th> */}
                                  <td>
                                    {/* <div style={{ display: `flex`, width: `100%` }}>
                                     <input
                                       class="form-control"
                                       placeholder={t("inputBankNamePleaseText")}
                                       value={selectedBank}
                                       disabled={true}
                                     ></input>
                                     <BankAccountChangeModal
                                       onSave={handleBankUpdate}
                                     />
                                   </div> */}

                                    {/* <input
                                     class="form-control"
                                     placeholder={t("accountSaveText")}
                                     value={isBankAccount}
                                     disabled={true}
                                   ></input> */}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <h3
                        className="content-title"
                        style={{ margin: `0rem 0 1.5rem`, fontWeight: `700` }}
                      >
                        {t("modifyProfileSaveText")}
                      </h3>
                      {languageData === "en" ? (
                        <QuillEditor></QuillEditor>
                      ) : (
                        <TextEditor data={editorData}></TextEditor>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      marginLeft: `2%`,
                      marginRight: `2%`,
                      marginTop: `2%`,
                    }}
                  >
                    {languageData === "en" ? (
                      <ReFooter
                        termsLink="/termosfuseCrEn"
                        personalInfoLink="/personalInfoEn"
                      />
                    ) : (
                      <ReFooter
                        termsLink="/termosfuseCr"
                        personalInfoLink="/personalInfo"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <MobileBackgroundDiv>
          <>
            <MSidBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              closeSidebar={closeSidebar}
              languageData={languageData}
              useData={useData}
              t={t}
              handleLinkPage={handleLinkPage}
              handleLinkThreePage={handleLinkThreePage}
            />
            <div className="container">
              <div className="container-fluid">
                <div className="container-xxl">
                  <div
                    style={{
                      maxHeight: `160px`,
                    }}
                  >
                    <CoupangAd src={CoupangSrc3} width="100%" height="100%" />
                  </div>
                  <MobileSizeBoxTop30 />
                  <div
                    style={{
                      fontSize: `1.5rem`,
                      fontWeight: `700`,
                      marginBottom: `20px`,
                    }}
                  >
                    {t("profileSettingText")}
                  </div>
                  <div className="table">
                    <table style={{ width: `100%` }}>
                      <colgroup>
                        <col style={{ width: `30%` }} />
                        <col style={{ width: `80%` }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <span
                              style={{
                                whiteSpace: `pre-line`,
                              }}
                            >
                              {t("nameTitleText")}
                            </span>
                          </th>
                          <td>
                            <input
                              style={{ textAlign: "left" }}
                              className="form-control"
                              disabled={true}
                              value={userProfileData?.profile?.name}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("titleNickName")}</th>

                          <td
                            style={{
                              display: `flex`,
                              width: `100%`,
                              alignItems: `center`,
                              height: `100%`,
                            }}
                          >
                            <input
                              className="form-control"
                              placeholder={t("inputNickNameText")}
                              value={nickName}
                              disabled={true}
                            ></input>
                            <NickNameChangeModal
                              onNickNameChange={handleNickNameChangee}
                            />{" "}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("callNumberText")}</th>
                          <td>
                            <input
                              className="form-control"
                              disabled={true}
                              value={isPhonNumber}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">E-Mail</th>
                          <td>
                            <input
                              className="form-control"
                              value={userProfileData?.profile?.email}
                              disabled={true}
                            ></input>{" "}
                          </td>
                        </tr>
                        <tr>
                          {typeLogin.type === "Kakao" ? (
                            <>
                              <th scope="row">{t("loginTypeText")}</th>
                              <td>
                                <ModifyTableKaKaoDivStyled>
                                  <ModifyTableImgDivStyled className="  btn-block">
                                    <LoginKaKaoWingStyled
                                      src={wing}
                                      alt="말풍선"
                                    />
                                    KaKao
                                  </ModifyTableImgDivStyled>
                                </ModifyTableKaKaoDivStyled>
                              </td>
                            </>
                          ) : (
                            <>
                              <th scope="row">{t("passwordText")}</th>
                              <td>
                                <div
                                  style={{
                                    display: `flex`,
                                    width: `100%`,
                                    alignItems: `center`,
                                  }}
                                >
                                  <input
                                    className="form-control"
                                    placeholder={t("passwordText")}
                                    type="password"
                                    value="password"
                                    // value={isPassword}
                                    disabled={true}
                                  ></input>
                                  <PasswordChangeModal
                                    onPassword={handlePasswordChange}
                                  />
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h3
                  className="content-title"
                  style={{ margin: `0rem 0 1.5rem`, fontWeight: `700` }}
                >
                  {t("modifyProfileSaveText")}
                </h3>
                {languageData === "en" ? (
                  <QuillEditor></QuillEditor>
                ) : (
                  <TextEditor data={editorData}></TextEditor>
                )}
              </div>
            </div>
          </>
          <MobileSizeBoxTop20 />
        </MobileBackgroundDiv>
      )}
    </>
  );
};
