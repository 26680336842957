import React from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import {
  MenuSidebar,
  MenuSidebarOverlay,
} from "../../MyPage/Renewal/css/ReMyPageMStyld";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { BsPersonAdd } from "react-icons/bs";
import { removeCookie } from "../../../common/Cookies/user_cookies";
import { resetStore } from "../../../common/redux/action/SidbarIndexAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { tokenName } from "../../../util/const";

export const MSidBar = ({
  isSidebarOpen,
  toggleSidebar,
  closeSidebar,
  languageData,
  useData,
  t,
  handleLinkPage,
  handleLinkThreePage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLink = () => {
    removeCookie(tokenName);
    dispatch(resetStore());
    navigate("/renewalLogin");
  };

  return (
    <div
      style={{
        display: `flex`,
        justifyContent: `space-between`,
        marginRight: `20px`,
        marginLeft: `20px`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          cursor: `pointer`,
        }}
      >
        <IoMenu
          size={40}
          onClick={toggleSidebar}
          style={{ cursor: "pointer" }}
        />
        <MenuSidebarOverlay isOpen={isSidebarOpen} onClick={closeSidebar} />
        <MenuSidebar isOpen={isSidebarOpen}>
          <div
            style={{
              display: `flex`,
              justifyContent: `space-between`,
            }}
          >
            <img
              src={languageData === "en" ? "/GroupEn.svg" : "/LogoBlack.svg"}
              alt=""
              style={{ width: `120px`, height: "55px" }}
            />
            <IoClose
              size={30}
              onClick={closeSidebar}
              style={{
                cursor: `pointer`,
              }}
            />
          </div>

          <ul className="gnb-list">
            <li>
              <Link to="/ReMyPage" onClick={() => handleLinkPage(0)}>
                <span
                  className="text"
                  style={{
                    fontSize: languageData === "en" ? `10px` : `18px`,
                  }}
                >
                  {t("sponsorShipStatusText")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/ReModifyPage" onClick={() => handleLinkPage(1)}>
                <span
                  className="text"
                  style={{
                    fontSize: languageData === "en" ? `10px` : `18px`,
                  }}
                >
                  {t("profileSettingText")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/reCreateButton" onClick={() => handleLinkPage(2)}>
                <span
                  className="text"
                  style={{
                    fontSize: languageData === "en" ? `10px` : `18px`,
                  }}
                >
                  {t("buttonCreateText")}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/CalculatePage"
                data-index="3"
                onClick={handleLinkThreePage}
              >
                <span
                  className="text"
                  style={{
                    fontSize: languageData === "en" ? `10px` : `18px`,
                  }}
                >
                  {t("settlementRegistrationText")}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/Confirmation"
                data-index="3"
                onClick={() => handleLinkPage(5)}
              >
                <span
                  className="text"
                  style={{
                    fontSize: languageData === "en" ? `10px` : `18px`,
                  }}
                >
                  {t("confirmationText")}
                </span>
              </Link>
            </li>
            <li>
              {languageData === "en" ? (
                <Link to="https://splendid-tuberose-a37.notion.site/HANZAN-Guideline-989ae6831bf7416688d4375c78e7bf1d">
                  <span
                    className="text"
                    style={{
                      fontSize: languageData === "en" ? `10px` : `18px`,
                    }}
                  >
                    {t("guideText")}
                  </span>
                </Link>
              ) : (
                <Link to="https://onemorelatte.oopy.io/" data-index="4">
                  <span className="text">{t("guideText")}</span>
                </Link>
              )}
            </li>
          </ul>
        </MenuSidebar>
      </div>

      <div
        style={{
          display: `flex`,
          height: `40px`,
          alignItems: `center`,
        }}
      >
        <div
          style={{
            height: `30px`,
            width: `30px`,
            alignItems: `center`,
            marginRight: `10px`,
          }}
        >
          {useData?.profileImgPath ? (
            <img
              src={useData?.profileImgPath}
              alt="프로필이미지"
              onError={(e) => (e.target.style.display = "none")}
            />
          ) : (
            <BsPersonAdd style={{ height: `30px`, width: `30px` }} />
          )}
        </div>
        <div>{useData?.name}</div>
        <button
          className="btn btn-sm btn-gray"
          onClick={handleLink}
          style={{
            height: `20px`,
            fontSize: `12px`,
            marginLeft: `10px`,
          }}
        >
          {languageData == "en" ? <>Logout</> : <>로그아웃</>}
        </button>
      </div>
    </div>
  );
};
