import axios from "axios";
import api from "../../../Repository/Interceptor";

export const DonationRepo = async (uuid, referrer) => {
  try {
    const resp = await api.get(
      `/donation/checkuuid/${uuid}?referrer=${referrer}`
    );

    console.log("도네이션 이미지 가져오기 성공", resp);
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("도네이션 프로필 이미지 가져오기 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
