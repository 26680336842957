import React, { memo } from "react";

// 후원한잔 PC 사이드바 광고
export const CoupangSrc =
  "https://ads-partners.coupang.com/widgets.html?id=810947&template=carousel&trackingCode=AF4554065&subId=&width=180&height=600&tsource=";
// 후원한잔 PC & 모바일 로그인화면 광고
export const CoupangSrc2 =
  "https://ads-partners.coupang.com/widgets.html?id=810947&template=carousel&trackingCode=AF4554065&subId=&width=640&height=150&tsource=";
// 후원한잔 모바일 MyPage 광고
export const CoupangSrc3 =
  "https://ads-partners.coupang.com/widgets.html?id=810947&template=carousel&trackingCode=AF4554065&subId=&width=648&height=160&tsource=";
// 후원하기 페이지 모바일 광고
export const CoupangSrc4 =
  "https://ads-partners.coupang.com/widgets.html?id=810947&template=carousel&trackingCode=AF4554065&subId=&width=648&height=160&tsource=";
// 후원하기 페이지 PC 광고
export const CoupangSrc5 =
  "https://ads-partners.coupang.com/widgets.html?id=810947&template=carousel&trackingCode=AF4554065&subId=&width=180&height=700&tsource=";

const CoupangAd = memo(({ src, width, height }) => {
  return (
    <iframe
      src={src}
      width={width}
      height={height}
      frameBorder="0"
      scrolling="no"
      referrerPolicy="unsafe-url"
      title="Coupang Ad"
    ></iframe>
  );
});

export default CoupangAd;
