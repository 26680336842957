import { useEffect, useState } from "react";
import ReFooter from "../../../common/Footer/screen/ReFooter";
import TitleText from "../../CreateButton/components/TitleText/TitleText";
import SelectMonth from "../../MyPage/Renewal/components/SelectMonth";
import { ReSidBar } from "../../ReSidBar/ReSidBar";
import { FaRegHandPointRight } from "react-icons/fa";
import { CgLoadbar } from "react-icons/cg";
import { ConfirmationTable } from "../components/ConfirmationTable";
import { getSoa } from "../repo/ConfirmationRepo";
import {
  ConfirmationFormatDate,
  ConfirmationFormatWriteDate,
  convertDateFormat,
} from "../../../util/DateCalculation";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmationDate } from "../../../common/redux/action/ConfirmationAction";
import Swal from "sweetalert2";
import { Loading03 } from "../../../util/spinner";
import { useTranslation } from "react-i18next";
import {
  CardMarginRLBox,
  ConfirmationDivBox,
  ConfirmationDivBox2,
  ConfirmationDivBoxM,
  ConfirmationTableBox,
  InquiryTextBox,
} from "../css/ConfirmationStyled";
import { MSidBar } from "../../MSidBar/screen/MobileSidBar";
import {
  MobileBackgroundDiv,
  MobileBackgroundDiv2,
  MobileSizeBoxTop30,
} from "../../MyPage/Renewal/css/ReMyPageMStyld";
import { setSideBar } from "../../../common/redux/action/SidbarIndexAction";
import { getCalculateData } from "../../Calculate/repo/GetCalculateData";
import { setCalculate } from "../../../common/redux/action/CalculateAction";
import { useNavigate } from "react-router";
import { CreateButtonCardStyled } from "../../CreateButton/Renewal/css/ReCreateStyeld";
import CoupangAd, { CoupangSrc3 } from "../../../util/iframe";

export const ConfirmationScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);

  const confirmationData = useSelector(
    (state) => state.confirmationDate?.data || []
  );
  const [yearData, setYearData] = useState([]);
  const [monthData, setMonthData] = useState([]);

  const [endYearData, setEndYearData] = useState([]);
  const [endMonthData, setEndMonthData] = useState([]);

  /// 선택된 년도
  const [selectedYear, setSelectedYear] = useState("");
  const [endSelectedYear, setEndSelectedYear] = useState("");
  /// 선택된 달
  const [selectedMonth, setselectedMonth] = useState("");
  const [endSelectedMonth, setEndSelectedMonth] = useState("");

  const [isDateSearchError, setDateSearchError] = useState(false);

  const [confirmationList, setConfirmationList] = useState([]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // 현재 월 가져오기 (0부터 시작하므로 1을 더함)

    const years = [];
    for (let year = currentYear; year >= 2023; year--) {
      years.push(`${year} ${t("yearText")}`);
    }
    const months = [];

    for (let month = 1; month <= currentMonth; month++) {
      months.push(`${month} ${t("monthText")}`);
    }

    const allMonths = [];
    for (let month = 1; month <= 12; month++) {
      allMonths.push(`${month} ${t("monthText")}`);
    }
    // 역순으로 월 데이터를 생성합니다.
    const reversedMonths = [];
    for (let month = currentMonth; month >= 1; month--) {
      reversedMonths.push(`${month} ${t("monthText")}`);
    }
    setYearData(years);
    setMonthData(allMonths);

    setSelectedYear(`${currentYear} ${t("yearText")}`);
    setselectedMonth(`1 ${t("monthText")}`);
    setEndYearData(years);
    setEndMonthData(reversedMonths);
    setEndSelectedYear(years[0]);
    setEndSelectedMonth(reversedMonths[0]);
    const start = years[0] + months[0];
    const end = years[0] + reversedMonths[0];

    const startDate = convertDateFormat(start);
    const endDate = convertDateFormat(end);
    /// 여기서 초기값 통신
    const fetchData = async () => {
      const soaData = await getSoa(startDate, endDate);

      if (soaData.msg === "Success") {
        const formattedData = soaData.data.data.map((item) => ({
          ...item,
          date: ConfirmationFormatDate(item.date),
          writeDate: ConfirmationFormatWriteDate(item.writeDate),
        }));
        dispatch(setConfirmationDate(formattedData));
        setDateSearchError(false);
        setLoading(false);
      }

      if (soaData.msg === "Fail") {
        setDateSearchError(true);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (value) => {
    setSelectedYear(value);
  };

  const handleMonthChange = (value) => {
    setselectedMonth(value);
  };

  const handleEndSelectChange = (value) => {
    setEndSelectedYear(value);
  };

  const handleEndMonthChange = (value) => {
    setEndSelectedMonth(value);
  };

  const handleGetSoa = async () => {
    setLoading(true);

    const formatDate = (year, month) => {
      const formattedYear = year.replace(` ${t("yearText")}`, "").trim();
      const formattedMonth = month
        .replace(` ${t("monthText")}`, "")
        .trim()
        .padStart(2, "0");
      return `${formattedYear}-${formattedMonth}-01`;
    };

    const startDate = formatDate(selectedYear, selectedMonth);
    const endDate = formatDate(endSelectedYear, endSelectedMonth);

    if (startDate > endDate) {
      Swal.fire({
        title: `${t("inquiryCriteriaText")}`,
        icon: "error",
      });
      setLoading(false);
      return;
    } else {
    }

    const resp = await getSoa(startDate, endDate);
    if (resp.msg === "Success") {
      const formattedData = resp.data.data.map((item) => ({
        ...item,
        date: ConfirmationFormatDate(item.date),
        writeDate: ConfirmationFormatWriteDate(item.writeDate),
      }));
      dispatch(setConfirmationDate(formattedData));
      setDateSearchError(false);
      setLoading(false);

      return;
    }

    if (resp.msg === "Fail") {
      setDateSearchError(true);
      setLoading(false);

      return;
    }
  };

  /// 작은화면
  const useData = useSelector((state) => state.profileState.profile);
  const navigate = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLinkPage = async (index) => {
    dispatch(setSideBar(index));
  };
  const handleLinkThreePage = async () => {
    const resp = await getCalculateData();
    if (resp.msg === "Success") {
      dispatch(setCalculate(resp.data.data));
      handleLinkPage(3);
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.status === 403) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.status === 500) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.data.message === "등록한정보가 없음") {
        dispatch(setCalculate("nodata"));
        handleLinkPage(3);
        return;
      }
      handleLinkPage(0);
      navigate("/ReMyPage");
      console.log("실패", resp);
      return;
    }
  };
  return (
    <>
      {windowWidth >= 1100 ? (
        <div className="layout-type-main">
          <div className="wrapper nav-mode-1">
            <div id="container">
              <ReSidBar />
              <div className="content-body">
                <div id="content">
                  <div style={{ height: `35px` }}></div>
                  <CardMarginRLBox className="card">
                    <div className="card">
                      <TitleText text={t("confirmationText")} />
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <FaRegHandPointRight
                          style={{ width: "15px", height: "15px" }}
                        />
                        <strong style={{ marginLeft: `10px` }}>
                          {t("inquiryCheckText")}
                        </strong>
                      </div>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <FaRegHandPointRight
                          style={{ width: "15px", height: "15px" }}
                        />
                        <strong style={{ marginLeft: `10px` }}>
                          {t("inquiryLongText")}
                        </strong>
                      </div>
                      <ConfirmationDivBox>
                        <ConfirmationDivBox>
                          <ConfirmationDivBox2>
                            {t("inquiryBaseText")}
                          </ConfirmationDivBox2>
                          <SelectMonth
                            data={yearData}
                            onSelectChange={handleSelectChange}
                            value={selectedYear}
                          />
                          <div style={{ width: `10px` }}></div>
                          <SelectMonth
                            data={monthData}
                            onSelectChange={handleMonthChange}
                            value={selectedMonth}
                          />
                          <div>
                            <CgLoadbar
                              style={{
                                marginRight: `1rem`,
                                marginLeft: `1rem`,
                              }}
                            />
                          </div>

                          <SelectMonth
                            data={endYearData}
                            onSelectChange={handleEndSelectChange}
                            value={endSelectedYear}
                          />
                          <div style={{ width: `10px` }}></div>

                          <SelectMonth
                            data={endMonthData}
                            onSelectChange={handleEndMonthChange}
                            value={endSelectedMonth}
                          />
                          <div style={{ width: `10px` }}></div>
                          <InquiryTextBox
                            className="btn btn btn-primary"
                            onClick={handleGetSoa}
                          >
                            {t("inquiryText")}
                          </InquiryTextBox>
                        </ConfirmationDivBox>
                      </ConfirmationDivBox>
                    </div>
                    <div style={{ marginTop: `40px` }}>
                      {isLoading === false ? (
                        <ConfirmationTable data={confirmationData} />
                      ) : (
                        <ConfirmationTableBox>
                          <Loading03 />
                        </ConfirmationTableBox>
                      )}
                    </div>
                  </CardMarginRLBox>
                  <div
                    style={{
                      marginLeft: `2%`,
                      marginRight: `2%`,
                      marginTop: `2%`,
                    }}
                  >
                    {languageData === "en" ? (
                      <ReFooter
                        termsLink="/termosfuseCrEn"
                        personalInfoLink="/personalInfoEn"
                      />
                    ) : (
                      <ReFooter
                        termsLink="/termosfuseCr"
                        personalInfoLink="/personalInfo"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <MobileBackgroundDiv2>
          <>
            <MSidBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              closeSidebar={closeSidebar}
              languageData={languageData}
              useData={useData}
              t={t}
              handleLinkPage={handleLinkPage}
              handleLinkThreePage={handleLinkThreePage}
            />
            <div className="container">
              <div className="container-fluid">
                <div className="container-xxl">
                  <div
                    style={{
                      maxHeight: `160px`,
                    }}
                  >
                    <CoupangAd src={CoupangSrc3} width="100%" height="100%" />
                  </div>
                  <MobileSizeBoxTop30 />
                  <CardMarginRLBox className="card">
                    <div className="card">
                      <TitleText text={t("confirmationText")} />
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <FaRegHandPointRight
                          style={{ width: "15px", height: "15px" }}
                        />
                        <strong style={{ marginLeft: `10px` }}>
                          {t("inquiryCheckText")}
                        </strong>
                      </div>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <FaRegHandPointRight
                          style={{ width: "15px", height: "15px" }}
                        />
                        <strong style={{ marginLeft: `10px` }}>
                          {t("inquiryLongText")}
                        </strong>
                      </div>
                      <ConfirmationDivBox>
                        <ConfirmationDivBoxM>
                          <ConfirmationDivBox2>
                            {t("inquiryBaseText")}
                          </ConfirmationDivBox2>
                          <InquiryTextBox
                            className="btn btn btn-primary"
                            onClick={handleGetSoa}
                          >
                            {t("inquiryText")}
                          </InquiryTextBox>
                        </ConfirmationDivBoxM>
                      </ConfirmationDivBox>
                      <div>
                        <div
                          style={{
                            marginTop: `10px`,
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                          }}
                        >
                          {languageData === "en" && (
                            <div
                              style={{
                                marginRight: `10px`,
                                fontWeight: `700`,
                              }}
                            >
                              from
                            </div>
                          )}
                          <SelectMonth
                            data={yearData}
                            onSelectChange={handleSelectChange}
                            value={selectedYear}
                          />
                          <div style={{ width: `10px` }}></div>
                          <SelectMonth
                            data={monthData}
                            onSelectChange={handleMonthChange}
                            value={selectedMonth}
                          />
                          {languageData !== "en" && (
                            <div
                              style={{
                                marginLeft: `10px`,
                                fontWeight: `700`,
                              }}
                            >
                              부터
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: `10px`,
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                          }}
                        >
                          {languageData === "en" && (
                            <div
                              style={{
                                marginRight: `10px`,
                                fontWeight: `700`,
                              }}
                            >
                              to
                            </div>
                          )}
                          <SelectMonth
                            data={endYearData}
                            onSelectChange={handleEndSelectChange}
                            value={endSelectedYear}
                          />
                          <div style={{ width: `10px` }}></div>

                          <SelectMonth
                            data={endMonthData}
                            onSelectChange={handleEndMonthChange}
                            value={endSelectedMonth}
                          />
                          {languageData !== "en" && (
                            <div
                              style={{
                                marginLeft: `10px`,
                                fontWeight: `700`,
                              }}
                            >
                              까지
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: `40px` }}>
                      {isLoading === false ? (
                        <ConfirmationTable data={confirmationData} />
                      ) : (
                        <ConfirmationTableBox>
                          <Loading03 />
                        </ConfirmationTableBox>
                      )}
                    </div>
                  </CardMarginRLBox>
                </div>
              </div>
            </div>
          </>
        </MobileBackgroundDiv2>
      )}
    </>
  );
};
