import React, { useEffect, useState } from "react";
import { ReSidBar } from "../../ReSidBar/ReSidBar";
import ReFooter from "../../../common/Footer/screen/ReFooter";
import { IndividualPage } from "../components/Individual";
import { CorporateBusinessPage } from "../components/CorporateBusiness";
import { PrivateBusinessPage } from "../components/PrivateBusiness";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  BusinessButton,
  MQuickLinkButtonSize,
  MQuickLinkButtonSize3,
  QuickLinkButtonSize,
} from "../css/CorporateBusinessStyled";
import {
  MobileBackgroundDiv,
  MobileBackgroundDiv2,
  MobileSizeBoxTop30,
} from "../../MyPage/Renewal/css/ReMyPageMStyld";
import { MSidBar } from "../../MSidBar/screen/MobileSidBar";
import { useNavigate } from "react-router";
import { getCalculateData } from "../repo/GetCalculateData";
import { setCalculate } from "../../../common/redux/action/CalculateAction";
import { setSideBar } from "../../../common/redux/action/SidbarIndexAction";
import CoupangAd, { CoupangSrc3, CoupangSrc4 } from "../../../util/iframe";
import {
  DonateInfoTextBox,
  HtmlCodeBox,
  HtmlCodeInBox,
  LogoBlackBox,
} from "../../DonationPage/css/DontaionStyled";

export const CalculatePage = () => {
  const { t } = useTranslation();
  const calculateData = useSelector((state) => state.calculateState);
  const languageData = useSelector((state) => state.languageType.type);
  const useData = useSelector((state) => state.profileState.profile);

  const [selectedPage, setSelectedPage] = useState("individual");
  const [inDividualDisabled, setIndividualDisabled] = useState(false);
  const [inPrivateBusinessDisabled, setPrivateBusinessDisabled] =
    useState(false);
  const [inCorporateBusinessDisabled, setCorporateBusinessDisabled] =
    useState(false);

  const handleIndividualClick = () => {
    setSelectedPage("individual");
  };

  const handlePrivateBusinessClick = () => {
    setSelectedPage("privateBusiness");
  };

  const handleCorporateBusinessClick = () => {
    setSelectedPage("corporateBusiness");
  };

  useEffect(() => {
    if (calculateData?.data === "nodata") {
      setIndividualDisabled(false);
      setPrivateBusinessDisabled(false);
      setCorporateBusinessDisabled(false);
      setSelectedPage("individual");
    }

    if (calculateData?.data === null) {
      setIndividualDisabled(false);
      setPrivateBusinessDisabled(false);
      setCorporateBusinessDisabled(false);
      setSelectedPage("individual");
    }

    if (calculateData?.data?.type === 1) {
      setIndividualDisabled(false);
      setPrivateBusinessDisabled(true);
      setCorporateBusinessDisabled(true);
      setSelectedPage("individual");
    }

    if (calculateData?.data?.type === 2) {
      setIndividualDisabled(true);
      setPrivateBusinessDisabled(false);
      setCorporateBusinessDisabled(true);
      setSelectedPage("privateBusiness");
    }

    if (calculateData?.data?.type === 3) {
      setIndividualDisabled(true);
      setPrivateBusinessDisabled(true);
      setCorporateBusinessDisabled(false);
      setSelectedPage("corporateBusiness");
    }
  }, [calculateData]);

  const getLiStyle = (pageName, disabled) => {
    if (disabled) {
      return {
        backgroundColor: "#d3d3d3",
        color: "#a9a9a9",
        cursor: "not-allowed",
      };
    }
    return {
      backgroundColor: selectedPage === pageName ? "#2563eb" : "#fff",
      color: selectedPage === pageName ? "#fff" : undefined,

      cursor: "pointer",
    };
  };
  /// 작은화면
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLinkPage = async (index) => {
    dispatch(setSideBar(index));
  };

  const handleLinkThreePage = async () => {
    const resp = await getCalculateData();
    if (resp.msg === "Success") {
      dispatch(setCalculate(resp.data.data));
      handleLinkPage(3);
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.status === 403) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.status === 500) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.data.message === "등록한정보가 없음") {
        dispatch(setCalculate("nodata"));
        handleLinkPage(3);
        return;
      }
      handleLinkPage(0);
      navigate("/ReMyPage");
      console.log("실패", resp);
      return;
    }
  };

  useEffect(() => {
    const checkUserAgent = () => {
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkUserAgent();
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileBackgroundDiv2>
          <>
            <MSidBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              closeSidebar={closeSidebar}
              languageData={languageData}
              useData={useData}
              t={t}
              handleLinkPage={handleLinkPage}
              handleLinkThreePage={handleLinkThreePage}
            />
            <div className="container">
              <div className="container-fluid">
                <div className="container-xxl">
                  <div
                    style={{
                      marginTop: `20px`,
                      marginBottom: `20px`,
                      height: `200px`,
                      maxHeight: `160px`,
                      maxWidth: `648px`,
                    }}
                  >
                    <CoupangAd src={CoupangSrc4} width="100%" height="100%" />
                  </div>{" "}
                  <MobileSizeBoxTop30 />
                  <HtmlCodeBox>
                    <HtmlCodeInBox>
                      <LogoBlackBox>
                        {languageData === "en" ? (
                          <img
                            src="/GroupEn.svg"
                            style={{
                              width: `350px`,
                            }}
                          ></img>
                        ) : (
                          <img src="/LogoBlack.svg"></img>
                        )}
                        <DonateInfoTextBox>
                          {/* <div>정산등록은 PC를 이용해주세요.</div> */}
                          {t("UsePC")}
                        </DonateInfoTextBox>
                      </LogoBlackBox>
                    </HtmlCodeInBox>
                  </HtmlCodeBox>
                </div>
              </div>
            </div>
          </>
        </MobileBackgroundDiv2>
      ) : windowWidth >= 1100 ? (
        <div className="layout-type-main">
          <div className="wrapper nav-mode-1">
            <div id="container">
              <ReSidBar />
              <div className="content-body">
                <div id="content">
                  <section className="section section-guide">
                    <ul className="quick-link">
                      <QuickLinkButtonSize
                        onClick={handleIndividualClick}
                        disabled={inDividualDisabled}
                      >
                        <div
                          className="card"
                          style={getLiStyle("individual", inDividualDisabled)}
                        >
                          <div
                            style={{
                              display: `flex`,
                              justifyContent: `center`,
                            }}
                          >
                            <strong style={{ fontSize: `20px` }}>
                              {t("individualText")}
                            </strong>
                          </div>
                        </div>
                      </QuickLinkButtonSize>
                      <button
                        style={{ marginTop: `3%`, width: `100%` }}
                        onClick={handlePrivateBusinessClick}
                        disabled={inPrivateBusinessDisabled}
                      >
                        <div
                          className="card"
                          style={getLiStyle(
                            "privateBusiness",
                            inPrivateBusinessDisabled
                          )}
                        >
                          <div
                            style={{
                              display: `flex`,
                              justifyContent: `center`,
                            }}
                          >
                            <strong style={{ fontSize: `20px` }}>
                              {t("privateBusinessText")}
                            </strong>
                          </div>
                        </div>
                      </button>
                      <BusinessButton
                        onClick={handleCorporateBusinessClick}
                        disabled={inCorporateBusinessDisabled}
                      >
                        <div
                          className="card"
                          style={getLiStyle(
                            "corporateBusiness",
                            inCorporateBusinessDisabled
                          )}
                        >
                          <div
                            style={{
                              display: `flex`,
                              justifyContent: `center`,
                            }}
                          >
                            <strong style={{ fontSize: `20px` }}>
                              {t("corporateBusinessText")}
                            </strong>
                          </div>
                        </div>
                      </BusinessButton>
                    </ul>
                  </section>

                  <div
                    className="card"
                    style={{ marginLeft: `2%`, marginRight: `2%` }}
                  >
                    <h3
                      className="content-title"
                      style={{ marginRight: `1rem`, marginBottom: `1rem` }}
                    >
                      {t("settlementText")}
                    </h3>

                    {selectedPage === "individual" && <IndividualPage />}
                    {selectedPage === "privateBusiness" && (
                      <PrivateBusinessPage />
                    )}
                    {selectedPage === "corporateBusiness" && (
                      <CorporateBusinessPage />
                    )}
                  </div>
                  <br></br>
                  <div style={{ marginLeft: `2%`, marginRight: `2%` }}>
                    {languageData === "en" ? (
                      <ReFooter
                        termsLink="/termosfuseCrEn"
                        personalInfoLink="/personalInfoEn"
                      />
                    ) : (
                      <ReFooter
                        termsLink="/termosfuseCr"
                        personalInfoLink="/personalInfo"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <MobileBackgroundDiv>
          <>
            <MSidBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              closeSidebar={closeSidebar}
              languageData={languageData}
              useData={useData}
              t={t}
              handleLinkPage={handleLinkPage}
              handleLinkThreePage={handleLinkThreePage}
            />
            <div className="container">
              <div className="container-fluid">
                <div className="container-xxl">
                  <div
                    style={{
                      maxHeight: `160px`,
                    }}
                  >
                    <CoupangAd src={CoupangSrc3} width="100%" height="100%" />
                  </div>
                  <MobileSizeBoxTop30 />
                  <div className="content-body">
                    <div id="">
                      <section className="section section-guide">
                        {/* <ul className="quick-link"> */}
                        <ul className="quick-link">
                          <MQuickLinkButtonSize
                            onClick={handleIndividualClick}
                            disabled={inDividualDisabled}
                          >
                            <div
                              className="card"
                              style={getLiStyle(
                                "individual",
                                inDividualDisabled
                              )}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `center`,
                                }}
                              >
                                <strong style={{ fontSize: `15px` }}>
                                  {t("individualText")}
                                </strong>
                              </div>
                            </div>
                          </MQuickLinkButtonSize>
                          <button
                            style={{ width: `100%` }}
                            onClick={handlePrivateBusinessClick}
                            disabled={inPrivateBusinessDisabled}
                          >
                            <div
                              className="card"
                              style={getLiStyle(
                                "privateBusiness",
                                inPrivateBusinessDisabled
                              )}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `center`,
                                }}
                              >
                                <strong style={{ fontSize: `15px` }}>
                                  {t("privateBusinessText")}
                                </strong>
                              </div>
                            </div>
                          </button>
                          <MQuickLinkButtonSize3
                            onClick={handleCorporateBusinessClick}
                            disabled={inCorporateBusinessDisabled}
                          >
                            <div
                              className="card"
                              style={getLiStyle(
                                "corporateBusiness",
                                inCorporateBusinessDisabled
                              )}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `center`,
                                }}
                              >
                                <strong style={{ fontSize: `15px` }}>
                                  {t("corporateBusinessText")}
                                </strong>
                              </div>
                            </div>
                          </MQuickLinkButtonSize3>
                        </ul>
                      </section>

                      <div className="card">
                        <h3
                          className="content-title"
                          style={{ marginRight: `1rem`, marginBottom: `1rem` }}
                        >
                          {t("settlementText")}
                        </h3>

                        {selectedPage === "individual" && <IndividualPage />}
                        {selectedPage === "privateBusiness" && (
                          <PrivateBusinessPage />
                        )}
                        {selectedPage === "corporateBusiness" && (
                          <CorporateBusinessPage />
                        )}
                      </div>
                      <br></br>
                      <div style={{ marginLeft: `2%`, marginRight: `2%` }}>
                        {languageData === "en" ? (
                          <ReFooter
                            termsLink="/termosfuseCrEn"
                            personalInfoLink="/personalInfoEn"
                          />
                        ) : (
                          <ReFooter
                            termsLink="/termosfuseCr"
                            personalInfoLink="/personalInfo"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </MobileBackgroundDiv>
      )}
    </>
  );
};
