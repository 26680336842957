import styled from "styled-components";
import ReFooter from "../../../../common/Footer/screen/ReFooter";
import "../../../../css/common.css";
import "../../../../css/content.css";
import "../../../../css/layout.css";
import "../../../../css/style.css";
import { ReSidBar } from "../../../ReSidBar/ReSidBar";
import ButtonImg from "../../components/ButtonContainer/ButtonImg";

import { CopyToClipboard } from "react-copy-to-clipboard";
import ButtonContainer from "../../components/ButtonContainer/ButtonContainer";
import ButtonBox from "../components/ButtonBox";
import { useEffect, useRef, useState } from "react";
import {
  CreateButtonCardStyled,
  CreateButtonUpStyled,
  CreateButtonUpStyledM,
  CreateCardLinkCopyBoxStyled,
  CreateCardStyled,
  CreateContTitleDivStyled,
  CreateContentTitleStyled,
  CreateDivStyled,
  CreateFaLinkIconStyled,
  CreateFaRegIcon,
  CreateImgDivStyled,
  CreateImgStyled,
  CreateLinkTextStyled,
  CreateMarginDivStyled,
  CreateTitleDivStyled,
} from "../css/ReCreateStyeld";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import {
  bigButton,
  enBigButton,
  enSmallButton,
  enThumbnailButton,
  smallButton,
  thumbnailButton,
} from "../components/Buttons";
import { setSideBar } from "../../../../common/redux/action/SidbarIndexAction";
import { getCalculateData } from "../../../Calculate/repo/GetCalculateData";
import { setCalculate } from "../../../../common/redux/action/CalculateAction";
import { useNavigate } from "react-router";
import {
  MobileBackgroundDiv,
  MobileSizeBoxTop30,
} from "../../../MyPage/Renewal/css/ReMyPageMStyld";
import { MSidBar } from "../../../MSidBar/screen/MobileSidBar";
import CoupangAd, { CoupangSrc3 } from "../../../../util/iframe";

export const ReCreateButtonPage = () => {
  const useData = useSelector((state) => state.profileState.profile);
  const imgRef = useRef(null);
  const { t } = useTranslation();
  const languageData = useSelector((state) => state.languageType.type);

  const buttonURL = useSelector((state) => state?.buttonURL?.buttonUrl || "");

  const [selectedButton, setSelectedButton] = useState(null);

  const [selectedButton2, setSelectedButton2] = useState(null);

  const [isButtonHTML, setButtonHTML] = useState(null);
  const [isSmallButtonHTML, setSmallButtonHTML] = useState(null);

  const handleCopyHtmlCode = () => {
    if (isButtonHTML === null) {
      Swal.fire({ title: `${t("choiceButtonText")}`, icon: "error" });
      return;
    }

    const htmlCode = `<div style="display: flex; justify-content: center;">
    <a href="${buttonURL}">
        <img src="${isButtonHTML}" alt="">
    </a>
  </div>`;

    const textArea = document.createElement("textarea");
    textArea.value = htmlCode;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      Swal.fire({ title: `${t("htmlCodeCopyText")}`, icon: "success" });
    } catch (err) {
      Swal.fire({ title: `${t("htmlCodeCopyFailText")}`, icon: "error" });
    }
    document.body.removeChild(textArea);
  };

  const handleSmallButtonCopyHtmlCode = () => {
    if (isSmallButtonHTML === null) {
      Swal.fire({ title: `${t("choiceButtonText")}`, icon: "error" });
      return;
    }

    const htmlCode = `<div style="display: flex; justify-content: center;">
    <a href="${buttonURL}">
        <img src="${isSmallButtonHTML}" alt="">
    </a>
  </div>`;

    const textArea = document.createElement("textarea");
    textArea.value = htmlCode;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      Swal.fire({ title: `${t("htmlCodeCopyText")}`, icon: "success" });
    } catch (err) {
      Swal.fire({ title: `${t("htmlCodeCopyFailText")}`, icon: "error" });
    }
    document.body.removeChild(textArea);
  };

  const handleButtonClick = (button, address) => {
    setSelectedButton(button);
    setButtonHTML(address);
  };

  const handleButtonClick2 = (button, address) => {
    setSelectedButton2(button);
    setSmallButtonHTML(address);
  };

  const handleUrlCopy = (buttonURL) => {
    if (!buttonURL) {
      console.log("URL이 존재하지 않습니다.");
      return;
    }

    Swal.fire({
      title: `${t("copyClipboardText")}`,
      icon: "success",
    });
  };

  const downloadImg = (button) => {
    const filePath = button.src;

    if (filePath.startsWith("data:")) {
      const base64Data = filePath.split(",")[1];
      const byteString = atob(base64Data);
      const mimeString = filePath.split(",")[0].split(":")[1].split(";")[0];

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: mimeString });
      const fileExtension = mimeString.split("/")[1];
      const newFileName = `${t("acoffeeText")}.${fileExtension}`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = newFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const fileName = filePath.split("/").pop();
      const fileExtension = fileName.split(".").pop();
      const newFileName = `${t("acoffeeText")}.${fileExtension}`;

      const link = document.createElement("a");
      link.href = button.src;
      link.download = newFileName;
      link.click();
    }
  };
  /// 작은화면
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLinkPage = async (index) => {
    dispatch(setSideBar(index));
  };

  const handleLinkThreePage = async () => {
    const resp = await getCalculateData();
    if (resp.msg === "Success") {
      dispatch(setCalculate(resp.data.data));
      handleLinkPage(3);
    }

    if (resp.msg === "Fail") {
      if (resp.data.response.status === 403) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.status === 500) {
        navigate("/ReMyPage");
        return;
      }
      if (resp.data.response.data.message === "등록한정보가 없음") {
        dispatch(setCalculate("nodata"));
        handleLinkPage(3);
        return;
      }
      handleLinkPage(0);
      navigate("/ReMyPage");
      console.log("실패", resp);
      return;
    }
  };

  return (
    <>
      {windowWidth >= 1100 ? (
        <div className="layout-type-main">
          <div className="wrapper nav-mode-1">
            <div id="container">
              <ReSidBar />
              <div id="content">
                <br />
                <br />

                <CreateButtonCardStyled className="card">
                  <div className="content-title-area">
                    <CreateImgDivStyled>
                      <CreateImgStyled src="/group.svg" alt="" />
                      {languageData === "en" ? (
                        <img src="/GroupEn.svg" alt="" width={300} />
                      ) : (
                        <img src="/LogoBlack.svg" alt="" />
                      )}
                    </CreateImgDivStyled>
                    <CreateTitleDivStyled>
                      <CreateContentTitleStyled className="content-title">
                        {t("createCoffeeButtonText")}
                      </CreateContentTitleStyled>
                    </CreateTitleDivStyled>

                    <div className="info">
                      <div className="box-point mt-5">
                        <p>
                          <strong>* {t("coffeeAnnouncementText")}</strong>
                        </p>
                        <p>
                          <strong>* {t("coffeeAnnouncementText2")}</strong>
                        </p>
                        <p>
                          <strong>* {t("coffeeAnnouncementText3")}</strong>
                        </p>
                        {/* <p>
                       {" "}
                       * 원하시는 후원버튼을 마우스 왼쪽버튼으로 클릭시 해당
                       버튼에 대한 HTML 코드가 복사되며, 해당 HTML 코드를 블로그
                       배너 위젯에 사용하실 수 있습니다.
                     </p> */}
                        <span>
                          <strong>* {t("coffeeAnnouncementText4")}</strong>
                        </span>
                      </div>
                    </div>
                    <br />
                    <CreateTitleDivStyled>
                      <CreateContentTitleStyled className="content-title">
                        {t("donaLinkText")}
                      </CreateContentTitleStyled>
                    </CreateTitleDivStyled>
                    <br />
                    <div className="card">
                      <CreateCardStyled>
                        <CreateCardLinkCopyBoxStyled>
                          <CreateFaLinkIconStyled />
                          <CreateLinkTextStyled>
                            {buttonURL}
                          </CreateLinkTextStyled>
                        </CreateCardLinkCopyBoxStyled>
                        <CopyToClipboard
                          text={buttonURL}
                          onCopy={() => handleUrlCopy(buttonURL)}
                        >
                          <CreateButtonUpStyled>
                            {t("donaLinkCopyText")}
                          </CreateButtonUpStyled>
                        </CopyToClipboard>
                      </CreateCardStyled>
                    </div>
                  </div>
                  <div className="card-cont cont-visitor" id="cont-visitor">
                    <h4 className="cont-title">{t("linkTreeThumbnailText")}</h4>
                    <CreateContTitleDivStyled>
                      <CreateFaRegIcon />
                      <p>{t("linkTreeButtonImgInputText")}</p>
                    </CreateContTitleDivStyled>
                    <div className="box-point mt-5">
                      {languageData === "en" ? (
                        <ButtonContainer>
                          {enThumbnailButton.map((button, index) => (
                            <ButtonBox
                              key={index}
                              src={button.view}
                              sizetype={button.sizetype}
                              isSelected={selectedButton?.src === button.src}
                              onClick={() =>
                                handleButtonClick(button, button.address)
                              }
                              style={{ with: `400` }}
                            />
                          ))}
                        </ButtonContainer>
                      ) : (
                        <ButtonContainer>
                          {thumbnailButton.map((button, index) => (
                            <ButtonBox
                              key={index}
                              src={button.view}
                              sizetype={button.sizetype}
                              isSelected={selectedButton?.src === button.src}
                              onClick={() =>
                                handleButtonClick(button, button.address)
                              }
                              style={{ with: `400` }}
                            />
                          ))}
                        </ButtonContainer>
                      )}
                    </div>
                    <CreateDivStyled>
                      <button
                        className="btn btn-lg btn-primary"
                        style={{ marginRight: `20px` }}
                        onClick={handleCopyHtmlCode}
                      >
                        {t("codeCopyText")}
                      </button>

                      <button
                        className="btn btn-lg btn-primary"
                        onClick={() => downloadImg(selectedButton)}
                      >
                        {t("imgDownloadText")}
                      </button>
                    </CreateDivStyled>
                  </div>
                  <div className="card-cont cont-visitor" id="cont-visitor">
                    <h4 className="cont-title">
                      {t("forPostWritingButtonText")}
                    </h4>
                    <CreateContTitleDivStyled>
                      <CreateFaRegIcon />

                      <p>{t("forPostWritingInputText")}</p>
                    </CreateContTitleDivStyled>
                    <div className="box-point mt-5">
                      {languageData === "en" ? (
                        <ButtonContainer>
                          {enBigButton.map((button, index) => (
                            <ButtonBox
                              key={index}
                              src={button.view}
                              sizetype={button.sizetype}
                              isSelected={selectedButton?.src === button.src}
                              onClick={() =>
                                handleButtonClick(button, button.address)
                              }
                              style={{ with: `400` }}
                            />
                          ))}
                        </ButtonContainer>
                      ) : (
                        <ButtonContainer>
                          {bigButton.map((button, index) => (
                            <ButtonBox
                              key={index}
                              src={button.view}
                              sizetype={button.sizetype}
                              isSelected={selectedButton?.src === button.src}
                              onClick={() =>
                                handleButtonClick(button, button.address)
                              }
                              style={{ with: `400` }}
                            />
                          ))}
                        </ButtonContainer>
                      )}
                    </div>
                    <CreateDivStyled>
                      <button
                        className="btn btn-lg btn-primary"
                        style={{ marginRight: `20px` }}
                        onClick={handleCopyHtmlCode}
                      >
                        {t("codeCopyText")}
                      </button>

                      <button
                        className="btn btn-lg btn-primary"
                        onClick={() => downloadImg(selectedButton)}
                      >
                        {t("imgDownloadText")}
                      </button>
                    </CreateDivStyled>
                  </div>
                  <div className="content-title-area">
                    <br />
                  </div>
                  <div className="card-cont cont-visitor" id="cont-visitor">
                    <h4 className="cont-title">
                      {t("forPostWritingSmallButtonText")}
                    </h4>
                    <CreateContTitleDivStyled>
                      <CreateFaRegIcon />

                      <p>{t("blogWidgetText")}</p>
                    </CreateContTitleDivStyled>
                    <div className="box-point mt-5">
                      {languageData === "en" ? (
                        <ButtonContainer>
                          {enSmallButton.map((button, index) => (
                            <ButtonBox
                              key={index}
                              src={button.view}
                              sizetype={button.sizetype}
                              isSelected={selectedButton2?.src === button.src}
                              onClick={() =>
                                handleButtonClick2(button, button.address)
                              }
                            />
                          ))}
                        </ButtonContainer>
                      ) : (
                        <ButtonContainer>
                          {smallButton.map((button, index) => (
                            <ButtonBox
                              key={index}
                              src={button.view}
                              sizetype={button.sizetype}
                              isSelected={selectedButton2?.src === button.src}
                              onClick={() =>
                                handleButtonClick2(button, button.address)
                              }
                            />
                          ))}
                        </ButtonContainer>
                      )}
                    </div>
                    <CreateDivStyled>
                      <button
                        className="btn btn-lg btn-primary"
                        style={{ marginRight: `20px` }}
                        onClick={handleSmallButtonCopyHtmlCode}
                      >
                        {t("codeCopyText")}
                      </button>

                      <button
                        className="btn btn-lg btn-primary"
                        onClick={() => downloadImg(selectedButton2)}
                      >
                        {t("imgDownloadText")}
                      </button>
                    </CreateDivStyled>
                  </div>
                </CreateButtonCardStyled>

                <CreateMarginDivStyled>
                  {languageData === "en" ? (
                    <ReFooter
                      style={{ marginLeft: `1rem` }}
                      termsLink="/termosfuseCrEn"
                      personalInfoLink="/personalInfoEn"
                    />
                  ) : (
                    <ReFooter
                      style={{ marginLeft: `1rem` }}
                      termsLink="/termosfuseCr"
                      personalInfoLink="/personalInfo"
                    />
                  )}
                </CreateMarginDivStyled>
                <br />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <MobileBackgroundDiv>
          <>
            <MSidBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              closeSidebar={closeSidebar}
              languageData={languageData}
              useData={useData}
              t={t}
              handleLinkPage={handleLinkPage}
              handleLinkThreePage={handleLinkThreePage}
            />
            <div className="container">
              <div className="container-fluid">
                <div className="container-xxl">
                  <div
                    style={{
                      maxHeight: `160px`,
                    }}
                  >
                    <CoupangAd src={CoupangSrc3} width="100%" height="100%" />
                  </div>
                  <MobileSizeBoxTop30 />
                  <CreateButtonCardStyled className="card">
                    <CreateTitleDivStyled>
                      <CreateContentTitleStyled className="content-title">
                        {t("createCoffeeButtonText")}
                      </CreateContentTitleStyled>
                    </CreateTitleDivStyled>
                    <div className="info">
                      <div className="box-point mt-5">
                        <p>
                          <strong>* {t("coffeeAnnouncementText")}</strong>
                        </p>
                        <p>
                          <strong>* {t("coffeeAnnouncementText2")}</strong>
                        </p>
                        <p>
                          <strong>* {t("coffeeAnnouncementText3")}</strong>
                        </p>
                        {/* <p>
                       {" "}
                       * 원하시는 후원버튼을 마우스 왼쪽버튼으로 클릭시 해당
                       버튼에 대한 HTML 코드가 복사되며, 해당 HTML 코드를 블로그
                       배너 위젯에 사용하실 수 있습니다.
                     </p> */}
                        <span>
                          <strong>* {t("coffeeAnnouncementText4")}</strong>
                        </span>
                      </div>
                    </div>
                    <br />
                    <CreateTitleDivStyled>
                      <CreateContentTitleStyled
                        className=""
                        style={{
                          width: `100%`,
                          alignItems: `center`,
                          display: `flex`,
                          justifyContent: `space-between`,
                        }}
                      >
                        {t("donaLinkText")}
                        <CopyToClipboard
                          text={buttonURL}
                          onCopy={() => handleUrlCopy(buttonURL)}
                        >
                          <CreateButtonUpStyledM>
                            {t("donaLinkCopyText")}
                          </CreateButtonUpStyledM>
                        </CopyToClipboard>
                      </CreateContentTitleStyled>
                    </CreateTitleDivStyled>
                    <br />
                    <div className="card">
                      <CreateCardStyled>
                        <CreateCardLinkCopyBoxStyled>
                          <CreateFaLinkIconStyled />
                          <CreateLinkTextStyled>
                            {buttonURL}
                          </CreateLinkTextStyled>
                        </CreateCardLinkCopyBoxStyled>
                      </CreateCardStyled>
                    </div>

                    <div className="card-cont cont-visitor" id="cont-visitor">
                      <h4 className="cont-title">
                        {t("linkTreeThumbnailText")}
                      </h4>
                      <CreateContTitleDivStyled>
                        <CreateFaRegIcon />
                        <p>{t("linkTreeButtonImgInputText")}</p>
                      </CreateContTitleDivStyled>
                      <div className="box-point mt-5">
                        {languageData === "en" ? (
                          <ButtonContainer>
                            {enThumbnailButton.map((button, index) => (
                              <ButtonBox
                                key={index}
                                src={button.view}
                                sizetype={button.sizetype}
                                isSelected={selectedButton?.src === button.src}
                                onClick={() =>
                                  handleButtonClick(button, button.address)
                                }
                                style={{ with: `400` }}
                              />
                            ))}
                          </ButtonContainer>
                        ) : (
                          <ButtonContainer>
                            {thumbnailButton.map((button, index) => (
                              <ButtonBox
                                key={index}
                                src={button.view}
                                sizetype={button.sizetype}
                                isSelected={selectedButton?.src === button.src}
                                onClick={() =>
                                  handleButtonClick(button, button.address)
                                }
                                style={{ with: `400` }}
                              />
                            ))}
                          </ButtonContainer>
                        )}
                      </div>
                      <CreateDivStyled>
                        <button
                          className="btn btn-lg btn-primary"
                          style={{ marginRight: `20px` }}
                          onClick={handleCopyHtmlCode}
                        >
                          {t("codeCopyText")}
                        </button>

                        <button
                          className="btn btn-lg btn-primary"
                          onClick={() => downloadImg(selectedButton)}
                        >
                          {t("imgDownloadText")}
                        </button>
                      </CreateDivStyled>

                      <div className="card-cont cont-visitor" id="cont-visitor">
                        <h4 className="cont-title">
                          {t("forPostWritingButtonText")}
                        </h4>
                        <CreateContTitleDivStyled>
                          <CreateFaRegIcon />

                          <p>{t("forPostWritingInputText")}</p>
                        </CreateContTitleDivStyled>
                        <div className="box-point mt-5">
                          {languageData === "en" ? (
                            <ButtonContainer>
                              {enBigButton.map((button, index) => (
                                <ButtonBox
                                  key={index}
                                  src={button.view}
                                  sizetype={button.sizetype}
                                  isSelected={
                                    selectedButton?.src === button.src
                                  }
                                  onClick={() =>
                                    handleButtonClick(button, button.address)
                                  }
                                  style={{ with: `400` }}
                                />
                              ))}
                            </ButtonContainer>
                          ) : (
                            /// 여기맞지?
                            // <></>
                            <ButtonContainer>
                              {bigButton.map((button, index) => (
                                <ButtonBox
                                  key={index}
                                  src={button.view}
                                  sizetype={button.sizetype}
                                  isSelected={
                                    selectedButton?.src === button.src
                                  }
                                  onClick={() =>
                                    handleButtonClick(button, button.address)
                                  }
                                  style={{ with: `400` }}
                                />
                              ))}
                            </ButtonContainer>
                          )}
                        </div>
                        <CreateDivStyled>
                          <button
                            className="btn btn-lg btn-primary"
                            style={{ marginRight: `20px` }}
                            onClick={handleCopyHtmlCode}
                          >
                            {t("codeCopyText")}
                          </button>

                          <button
                            className="btn btn-lg btn-primary"
                            onClick={() => downloadImg(selectedButton)}
                          >
                            {t("imgDownloadText")}
                          </button>
                        </CreateDivStyled>
                      </div>
                      <div className="content-title-area">
                        <br />
                      </div>
                    </div>
                    <div className="card-cont cont-visitor" id="cont-visitor">
                      <h4 className="cont-title">
                        {t("forPostWritingSmallButtonText")}
                      </h4>
                      <CreateContTitleDivStyled>
                        <CreateFaRegIcon />

                        <p>{t("blogWidgetText")}</p>
                      </CreateContTitleDivStyled>
                      <div className="box-point mt-5">
                        {languageData === "en" ? (
                          <ButtonContainer>
                            {enSmallButton.map((button, index) => (
                              <ButtonBox
                                key={index}
                                src={button.view}
                                sizetype={button.sizetype}
                                isSelected={selectedButton2?.src === button.src}
                                onClick={() =>
                                  handleButtonClick2(button, button.address)
                                }
                              />
                            ))}
                          </ButtonContainer>
                        ) : (
                          <ButtonContainer>
                            {smallButton.map((button, index) => (
                              <ButtonBox
                                key={index}
                                src={button.view}
                                sizetype={button.sizetype}
                                isSelected={selectedButton2?.src === button.src}
                                onClick={() =>
                                  handleButtonClick2(button, button.address)
                                }
                              />
                            ))}
                          </ButtonContainer>
                        )}
                      </div>
                      <CreateDivStyled>
                        <button
                          className="btn btn-lg btn-primary"
                          style={{ marginRight: `20px` }}
                          onClick={handleSmallButtonCopyHtmlCode}
                        >
                          {t("codeCopyText")}
                        </button>

                        <button
                          className="btn btn-lg btn-primary"
                          onClick={() => downloadImg(selectedButton2)}
                        >
                          {t("imgDownloadText")}
                        </button>
                      </CreateDivStyled>
                    </div>
                  </CreateButtonCardStyled>
                </div>
              </div>
            </div>
          </>
          <MobileSizeBoxTop30 />
        </MobileBackgroundDiv>
      )}
    </>
  );
};
