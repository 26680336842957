import styled from "styled-components";
import React from "react";
export const OneDayBoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 8px 5px 8px 5px;
  border-radius: 8px 0 0 8px;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};
  cursor: pointer;

  @media (max-width: 1100px) {
    width: 4rem;
    font-size: 12px;
  }
  @media (max-width: 454px) {
    width: 3rem;
    font-size: 10px;
  }
`;

export const DayBoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 8px 5px 8px 5px;
  border-radius: 0px 0 0 0px;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};
  cursor: pointer;
  @media (max-width: 1100px) {
    width: 4rem;
    font-size: 12px;
  }
  @media (max-width: 454px) {
    width: 3rem;
    font-size: 10px;
  }
`;

export const BoxStyled = styled.div`
  border: 1px solid #d1d5db;
  padding: 8px 5px 8px 5px;
  border-radius: 0px 8px 8px 0px;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  color: #374151;
  background-color: ${(props) => (props.selected ? "#3b82f6" : "white")};
  color: ${(props) => (props.selected ? "white" : "#374151")};
  cursor: pointer;
  @media (max-width: 1100px) {
    width: 4rem;
    font-size: 12px;
  }
  @media (max-width: 454px) {
    width: 3rem;
    font-size: 10px;
  }
`;

export const ListBoxScreenMBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1100px) {
    justify-content: center;
    width: 100%;
    /* background-color: black; */
    font-size: 12px;
    align-items: center;
  }
`;
