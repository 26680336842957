import { Link } from "react-router-dom";
import styled from "styled-components";

export const TopButtonDivLinkMobile = styled(Link)`
  width: 100%;
  height: 80px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: 16px;
  background-color: white;
  font-size: 10px;
`;

export const TopButtonDivLinkMobileR = styled(Link)`
  width: 100%;
  height: 80px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 20px; */
  border-radius: 16px;
  font-size: 10px;
  background-color: white;
`;

export const TopButtonDivBoxMobile2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileBackgroundDiv = styled.div`
  background-color: rgba(166, 173, 185, 0.2);
  min-height: 100vh;
  height: auto;
`;

export const MobileBackgroundDiv2 = styled.div`
  background-color: rgba(166, 173, 185, 0.2);
  height: auto;
  min-height: 100vh;
`;

export const MobileSizeBoxTop50 = styled.div`
  height: 50px;
`;
export const MobileSizeBoxTop30 = styled.div`
  height: 30px;
`;

export const MobileSizeBoxTop20 = styled.div`
  height: 20px;
`;

export const MobileTopIconDivBox = styled.div`
  background-color: ${(props) => props.bgColor || "#f5a207"};
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

export const MenuSidebar = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) =>
    props.isOpen ? "0" : "-250px"}; // 사이드바가 열리고 닫힐 때 위치 변경
  width: 200px;
  height: 150vh;
  background-color: white;
  color: black;
  transition: left 0.3s ease; // 부드러운 슬라이딩 효과
  z-index: 1000;
  padding: 20px;
  cursor: default; // 기본 커서 설정
`;

// const MenuContent = styled.div`
//   margin-left: ${(props) =>
//     props.isOpen ? "250px" : "0"}; // 사이드바가 열리면 콘텐츠가 밀려남
//   transition: margin-left 0.3s ease;
// `;

export const MenuSidebarOverlay = styled.div`
  position: ${(props) => (props.isOpen ? "fixed" : "none")};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.5); // 반투명 배경 */
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 999;
`;
