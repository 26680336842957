import api from "../../../../Repository/Interceptor";
import { formatKoreanPhoneNumber } from "../../../../util/const";

export const postAuthSignup = async (email, nick, name, pw, phon, nation) => {
  try {
    if (phon.startsWith("+82")) {
      const formattedPhone = formatKoreanPhoneNumber(
        phon.replace("+82", "").trim()
      );
      phon = "+82 " + formattedPhone;
    }

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", pw);
    formData.append("phone", phon);
    formData.append("name", name);
    formData.append("nickname", nick);
    formData.append("nation", nation);

    const resp = await api.post("/auth/signup", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("회원가입 로직 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
