import { useState } from "react";
import { checkText } from "../../../common/publicText/PublicText";
import { useTranslation } from "react-i18next";
import { AiFillAlert } from "react-icons/ai";

export const EmailModal = ({ text }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <div
      style={{ width: `10rem`, display: `flex`, justifyContent: `flex-end` }}
    >
      <button
        style={{
          backgroundColor: "#FFD400",
          justifyContent: `center`,
          alignItems: `center`,
          display: `flex`,
          height: text === true ? `100%` : `3.5rem`,
          borderRadius: `8px`,
          width: text === false && `50px`,
        }}
        onClick={toggleModal}
      >
        <div
          style={{
            width: `10rem`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            paddingBottom: `10px`,
            paddingTop: `10px`,
          }}
        >
          <AiFillAlert
            style={{
              height: `2rem`,
              width: `2rem`,
              marginRight: text === true ? `10px` : `0px`,
            }}
          />
          {text === true && (
            <div>
              <strong>{t("contactUs")}</strong>
            </div>
          )}
        </div>
      </button>

      {showModal && (
        <div className="modal-popup modal-sm" id="layer-sample">
          <div className="dimed"></div>
          <div className="popup-inner">
            <div className="popup-header">
              <h2 className="popup-title"> {t("contactUs")}</h2>
            </div>
            <div className="popup-body">
              <div
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                  fontSize: `18px`,
                  fontWeight: `700`,
                }}
              >
                {t("inconvenienceText")}
              </div>
              <div
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                  fontSize: `15px`,
                  marginTop: `10px`,
                }}
              >
                dltmdtjr9683@xrai-api.com{" "}
              </div>
            </div>
            <div className="popup-footer">
              <div className="btn-group-center">
                <button
                  type="button"
                  className="btn  btn-lg btn-gray"
                  data-modal="close"
                  onClick={toggleModal}
                >
                  {t("cancellationText")}
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  data-modal="close"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  {t("checkText")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
